<template>
  <div class="table_sty">
    <div class="froms" style="margin: 5px">
      <div>基本信息</div>
      <div class="wire"></div>
      <el-col :span="24">
        <div class="message">
          <p>制度管理名称：</p>
          <p class="p1">{{ data.name }}</p>
          <div class="sign">
            <div class="sign-1" :style="{ background: data.back_color }">
              {{ data.level_type }}
            </div>
            <div
              class="sign-2"
              :style="{
                border: `1px  solid ${data.back_color}`,
                color: data.back_color,
              }"
            >
              {{ data.level_color }}
            </div>
          </div>
        </div>
      </el-col>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="message">
            <p>起草部门：</p>
            <p class="p1">{{ data.drafts_department }}</p>
          </div>
        </el-col>
        <!-- <el-col :span="8">
          <div class="message">
            <p>生效时间：</p>
            <p class="p1">{{data.begin_time}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>失效时间：</p>
            <p class="p1">{{data.end_time}}</p>
          </div>
        </el-col> -->
        <el-col :span="24">
          <div class="message">
            <p>详情：</p>
            <p class="p1" v-html="data.detail"></p>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="froms particulars-pop">
      <tablepop :id="id" url_type="regulation" ref="tablepop" />
    </div>
  </div>
</template>

<script>
import { regulationdetail } from "../../assets/request/api";
import tablepop from "../../components/table_pop";
export default {
  name: "system_particulars",
  components: { tablepop },
  data() {
    return {
      id: "",
      data: {},
    };
  },
  created() {
    let { id } = this.$route.query;
    this.id = id;
    regulationdetail({ id }).then((res) => {
      this.data = res.data.data;
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.message {
  display: flex;
  font-size: 15px;
  color: #28307c;
  margin-top: 10px;
  .p1 {
    color: #465269;
  }
}
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.result {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #19be6b;
  }
}
.wire {
  height: 1px;
  background: #e8eaec;
  margin: 10px -15px 0 -15px;
}
.sign {
  margin-left: 30px;
  display: flex;
  div {
    width: 30px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
    box-sizing: border-box;
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}
.sign-2 {
  border-radius: 0 5px 5px 0;
}
</style>
